<template>
  <div class="page">
    <Navbar title="项目经验" :callback="back" type="SMP" />
    <div class="skill">
      <van-row class="title">
        <van-col span="4">时间</van-col>
        <van-col span="14">名称</van-col>
        <van-col span="6">操作</van-col>
      </van-row>
      <van-row class="record" v-for="honor in honorList" :key="honor.id">
        <van-col span="4">{{ honor.issueDate }}</van-col>
        <van-col span="14">{{ honor.name }}</van-col>
        <van-col span="6"
          ><van-tag :color="COLOR" plain @click="updateHonor(honor.seq)"
            >修改</van-tag
          >&nbsp;
          <van-tag type="danger" plain @click="deleteHonor(honor.seq)"
            >删除</van-tag
          ></van-col
        >
      </van-row>
      <van-row class="no-record" v-if="honorList.length <= 0">
        <van-col span="24">
          <van-image
            width="103"
            height="103"
            :src="require('../../../../assets/images/home/no-record.png')"
          ></van-image
        ></van-col>
      </van-row>
    </div>
    <van-row class="btn">
      <van-col span="24">
        <van-button
          :color="COLOR"
          icon="plus"
          size="small"
          @click="createHonor()"
          >新增荣誉</van-button
        >
      </van-col>
    </van-row>
  </div>
</template>
<script>
import { Icon, Tag, Image } from 'vant'
import Navbar from '../../common/Navbar.vue'
export default {
  components: {
    Navbar: Navbar,
    [Icon.name]: Icon,
    [Tag.name]: Tag,
    [Image.name]: Image
  },
  data () {
    return {
      personCode: '',
      honorList: []
    }
  },
  mounted () {
    var query = this.$route.query
    this.personCode = query.personCode
    this.retrieveHonorList()
  },
  methods: {
    async retrieveHonorList () {
      var pd = { personCode: this.personCode, group: 'HNR', type: 'DTL' }
      this.honorList = []
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/retrievePersonAttrGroupList', this.$qs.stringify(pd))
      if (res.status === '200') {
        res.data.forEach(element => {
          var content = JSON.parse(element.content)
          var honor = {
            seq: element.seq,
            name: content.name,
            issueDate: content.issueDate
          }
          this.honorList.push(honor)
        })
      }
    },
    createHonor () {
      this.$router.push({ path: '/mde/people/person/dtlHonorEdit', query: { personCode: this.personCode, seq: this.honorList.length + 1 } })
    },
    updateHonor (seq) {
      this.$router.push({ path: '/mde/people/person/dtlHonorEdit', query: { personCode: this.personCode, seq: seq } })
    },
    deleteHonor (seq) {
      this.$dialog.confirm({
        title: '删除确认',
        message: '确认删除个人荣誉吗?'
      })
        .then(() => {
          this.deleteHonorExecute(seq)
        })
    },
    async deleteHonorExecute (seq) {
      var pd = { key: 'PSN_HNR_ITEM', personCode: this.personCode, type: 'DTL', seq: seq }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/deletePersonAttr', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.retrieveHonorList()
      } else {
        this.$dialog.alert({
          title: '提示信息',
          message: '删除失败:' + res.msg
        })
      }
    },
    back () {
      var url = window.sessionStorage.getItem(this.SESSION_BACKURL)
      window.location.href = url
    }
  }
}
</script>
<style lang="less" scoped>
.skill {
  margin: 5px;
  .title {
    font-size: 14px;
    font-weight: 600;
    height: 35px;
    line-height: 35px;
    text-align: center;
    border-bottom: 1px solid #eee;
  }
  .record {
    height: 35px;
    line-height: 35px;
    border-bottom: 1px solid #eee;
  }
}
.btn {
  margin-top: 10px;
}
</style>
